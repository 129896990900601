import { Button, Input } from '@chakra-ui/react';
import { type GetServerSideProps } from 'next';
import Head from 'next/head';
import { useId, type FC } from 'react';
import './login.css';
import { auth0 } from '@/util/auth0/server';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const session = await auth0(context.req).getSession(context.req, context.res);

  if (session?.accessTokenExpiresAt * 1000 > Date.now()) {
    return {
      redirect: {
        destination: '/',
        permanent: false,
      },
    };
  }

  return Promise.resolve({
    props: {
      returnTo: context.query.returnTo ?? '',
    },
  });
};

const Login: FC<{ returnTo: string }> = ({ returnTo }) => {
  const orgFieldID = useId();

  return (
    <form className="login-form" action="/api/auth/login" method="get">
      <Head>
        <title>Clarasight | Login</title>
      </Head>

      <legend>Sign in to Clarasight</legend>

      <input type="hidden" name="returnTo" value={returnTo} />

      <label htmlFor={orgFieldID}>Organization</label>
      <Input
        id={orgFieldID}
        name="orgName"
        type="text"
        onBlur={(e) => {
          e.target.value = e.target.value.toLowerCase();
        }}
        pattern="^[a-z0-9_\-]+$"
        placeholder="example_co"
        required
        autoFocus
      />
      <p className="field__help">
        This is all lowercase, with words separated by underscores or dashes, e.g.{' '}
        <code className="nowrap">example-co</code>.
      </p>

      <footer>
        <Button type="submit">Sign in</Button>
      </footer>
    </form>
  );
};

export default Login;
